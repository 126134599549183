import { LogUtilService } from '@modules/util/log-util.service';

export function ngxsLogFilter(action: any, state: any): boolean {
  if (action && action.routerState == undefined) {
    if(LogUtilService.hasKeyDeep(action, 'password')) {
      return false;
    }
  }
  return true; // Return false if you want to filter out the action completely
}
