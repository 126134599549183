import { inject, Injectable } from '@angular/core';

import { ClipboardService as NgxClipboardService } from 'ngx-clipboard';
import copyToClipboard from 'copy-to-clipboard';
import { CopyToClipboardOptions } from '@common/models';

/**
 * Plasma Games Clipboard Service
 *
 * TODO - Find out differences in clipboard implementations and verify whether both is needed for os/browser polyfills
 */
@Injectable({ providedIn: 'root' })
export class ClipboardService {
  private readonly ngxClipboard = inject(NgxClipboardService);

  /**
   * Copy the given text to the user's clipboard
   * @param text
   * @param options
   */
  public copy(text: string, options?: CopyToClipboardOptions) {
    if (options) {
      copyToClipboard(text, options);
    } else {
      this.ngxClipboard.copy(text);
    }
    // TODO - fallback, use window.navigator?
    // window.navigator.clipboard.writeText();
  }
}
