import { Injectable, signal, effect } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataCyService {
  counters = signal<{ [key: string]: number }>({});

  // Keep a record of existing data-cy values and prevent them from being changed
  // when test suite navigates away and returns to the same page
  existingDataCyValues: {
    [key: string]: {
      counter: number;
      dataCyValue: string;
      lockDataCyValue?: boolean;
    };
  } = {};

  constructor() {
    this.loadState();
    effect(() => {
      this.saveState();
    });
  }

  // get existing data to persist during testing
  private loadState() {
    const storedState = localStorage.getItem('dataCyService');
    if (storedState) {
      const state = JSON.parse(storedState);
      this.existingDataCyValues = state.existingDataCyValues;
    }
  }

  // Make data persist during testing to insure components don't dynamically change
  private saveState() {
    const state = {
      existingDataCyValues: this.existingDataCyValues,
    };
    localStorage.setItem('dataCyService', JSON.stringify(state));
  }

  generateDataCy(componentName: string, componentType: string): string {
    const pageSpecificKey = componentName;
    const key = `${pageSpecificKey}-${componentType}`;

    if (this.existingDataCyValues[key] && !this.existingDataCyValues[key].lockDataCyValue) {
      const currentCounter = this.existingDataCyValues[key].counter;
      const dataCyValue = `${pageSpecificKey}-${componentType}_${currentCounter + 1}`;

      this.existingDataCyValues[key] = {
        counter: currentCounter + 1,
        dataCyValue,
      };
      return dataCyValue;
    } else {
      if (this.existingDataCyValues) this.lockExistingDataCyValue(pageSpecificKey);
      const dataCyValue = `${pageSpecificKey}-${componentType}_0`;

      this.existingDataCyValues[key] = {
        counter: 0,
        dataCyValue,
        lockDataCyValue: false,
      };

      return dataCyValue;
    }
  }

  // Check that the component has not been visited before locking previous data-cy values
  lockExistingDataCyValue(componentName: string) {
    if (!this.existingDataCyValues) return;

    for (let key in this.existingDataCyValues) {
      const keyComponentName = key.substring(0, key.lastIndexOf('-'));
      if (keyComponentName !== componentName) {
        this.existingDataCyValues[key].lockDataCyValue = true;
      }
    }
  }
}
